import { put, call, takeLatest } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetVisited } from '../services/webAppEndPoint';

function* loadVisited() {
  try {
    const { visited, moreItems, nextCursor } = yield call(GetVisited);
    yield put(actions.loadVisitedSuccess(visited, moreItems, nextCursor));
  } catch (error) {
    yield put(actions.loadVisitedFailure(error));
  }
}

function* loadMoreVisited({ nextCursor }) {
  try {
    const { visited, moreItems, nextCursor: nextCursorr } = yield call(GetVisited, nextCursor);
    yield put(actions.loadMoreVisitedSuccess(visited, moreItems, nextCursorr));
  } catch (error) {
    yield put(actions.loadMoreVisitedFailure(error));
  }
}

function* handleLoadVisitors() {
  yield takeLatest(types.loadVisited, loadVisited);
}

function* handleLoadMore() {
  yield takeLatest(types.loadMoreVisited, loadMoreVisited);
}

export default {
  handleLoadVisitors,

  handleLoadMore,
};
