const loadVisited = 'app/visitors/LOADVISITED';
const loadVisitedSuccess = 'app/visitors/LOADVISITEDSUCCESS';
const loadVisitedFailure = 'app/visitors/LOADVISITEDFAILURE';
const loadMoreVisited = 'app/visitors/LOADMOREVISITED';
const loadMoreVisitedSuccess = 'app/visitors/LOADMOREVISITEDSUCCESS';
const loadMoreVisitedFailure = 'app/visitors/LOADMOREVISITEDFAILURE';

export default {
  loadVisited,
  loadVisitedSuccess,
  loadVisitedFailure,
  loadMoreVisited,
  loadMoreVisitedSuccess,
  loadMoreVisitedFailure,
};
