import { createSelector } from 'reselect';
import { reverse, sortBy } from 'lodash';

const getVisitedPart = (state) => state.visited;
const getCollection = (state) => getVisitedPart(state).collection;
const getAllVisitedIds = (state) => getCollection(state).allIds;
const getVisitedById = (state) => getCollection(state).byId;
const getIsLoading = (state) => getVisitedPart(state).loading;
const getError = (state) => getVisitedPart(state).error;
const getNextPage = (state) => getVisitedPart(state).nextPage;
const getMoreItems = (state) => getVisitedPart(state).moreItems;

const getAllVisited = createSelector(getAllVisitedIds, getVisitedById, (ids, items) =>
  ids.map((id) => items[id])
);

const getSortedVisited = createSelector(getAllVisited, (items) =>
  reverse(sortBy(items, 'createdAt'))
);

export default {
  getSortedVisited,
  getIsLoading,
  getError,
  getNextPage,
  getMoreItems,
};
