import types from './types';

const loadVisited = () => ({ type: types.loadVisited });
const loadVisitedSuccess = (visited, moreItems, nextCursor) => ({
  type: types.loadVisitedSuccess,
  visited,
  moreItems,
  nextCursor,
});
const loadVisitedFailure = (error) => ({ type: types.loadVisitedFailure, error });
const loadMoreVisited = (nextCursor) => ({ type: types.loadMoreVisited, nextCursor });
const loadMoreVisitedSuccess = (visited, moreItems, nextCursor) => ({
  type: types.loadMoreVisitedSuccess,
  visited,
  moreItems,
  nextCursor,
});
const loadMoreVisitedFailure = (error) => ({ type: types.loadMoreVisitedFailure, error });

export default {
  loadVisited,
  loadVisitedSuccess,
  loadVisitedFailure,
  loadMoreVisited,
  loadMoreVisitedSuccess,
  loadMoreVisitedFailure,
};
