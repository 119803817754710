import { uniq, keyBy } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.loadVisitedSuccess: {
      const { visited } = action;
      return uniq(visited.map(({ id }) => id));
    }
    case types.loadMoreVisitedSuccess: {
      const { visited } = action;
      return uniq([...state, ...visited.map(({ id }) => id)]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.loadVisitedSuccess: {
      const { visited } = action;
      return keyBy(visited, 'id');
    }
    case types.loadMoreVisitedSuccess: {
      const { visited } = action;
      return { ...state, ...keyBy(visited, 'id') };
    }
    default:
      return state;
  }
};

const loaderReducer = (state = false, { type }) => {
  switch (type) {
    case types.loadVisited:
    case types.loadMoreVisited:
      return true;
    case types.loadMoreVisitedSuccess:
    case types.loadMoreVisitedFailure:
    case types.loadVisitedFailure:
    case types.loadVisitedSuccess:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, action) => {
  const { type, error } = action;
  switch (type) {
    case types.loadVisited:
    case types.loadVisitedSuccess:
      return null;
    case types.loadVisitedFailure:
      return error;
    default:
      return state;
  }
};

const pageReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.loadVisitedSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    case types.loadMoreVisitedSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    default:
      return state;
  }
};

const moreItemsReducer = (state = true, action) => {
  const { type, moreItems } = action;
  switch (type) {
    case types.loadMoreVisitedSuccess:
    case types.loadVisitedSuccess:
      return moreItems;
    default:
      return state;
  }
};

export default combineReducers({
  loading: loaderReducer,
  error: errorReducer,
  collection: combineReducers({ byId: byIdReducer, allIds: allIdsReducer }),
  nextPage: pageReducer,
  moreItems: moreItemsReducer,
});
